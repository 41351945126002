.swiper {
  width: 100%;
  height: 70%;
  padding: 2px 0 !important;
  position: static !important;

  /* margin-bottom: 2rem; */

  /* margin: auto 0; */
}

.swiper-wrapper {
  align-items: center;
}

.swiper-pagination {
  margin-bottom: 2rem;
}

.swiper-pagination-bullet {
  width: 5rem !important;
  height: 1rem !important;
  /* width: 3rem !important; */
  /* height: 0.6rem !important; */
  border-radius: 16px !important;
  margin: 0 0.5rem !important;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(4.5px) !important;
  -webkit-backdrop-filter: blur(4.5px) !important;
}

.swiper-pagination-bullet-active {
  background: #ffa200 !important;
}

.swiper-slide {
  text-align: center;
  /* font-size: 18px; */
  border-radius: 16px;
  overflow: hidden;
  /* width: 70% !important; */
  /* width: 85% !important; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-prev,
.swiper-slide-next {
  height: 90% !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: 0.5s ease;
  /* backface-visibility: hidden; */
}

.swiper-slide a {
  text-decoration: none;
}

.swiper-slide-active .project-desc-container {
  display: flex;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.5s ease;
}

.swiper-slide-active:hover .project-desc-container {
  height: 100%;
  opacity: 1;
  background: rgba(0, 0, 0, 0.8) !important;
}

@media (min-width: 992px) {
  .swiper-pagination-bullet {
    width: 4rem !important;
    height: 0.8rem !important;
  }
}
