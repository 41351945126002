.roboto {
  font-family: "Roboto Mono", monospace;
}

/* TEXT COLORS */
.color-black {
  color: #1a1a1a;
}
.color-primary {
  color: #ffa200;
}
.color-secondary {
  color: #ffdb9b;
}
.color-tertiary {
  color: #fff2db;
}

/* BG COLORS */
.bg-color-black {
  background-color: #1a1a1a;
}
.bg-color-primary {
  background-color: #ffa200;
}
.bg-color-secondary {
  background-color: #ffdb9b;
}
.bg-color-tertiary {
  background-color: #fff2db;
}

/* TYPOGRAPHY */
.heading-primary {
  font-size: 7.2rem;
  line-height: 1;
  font-weight: 700;
}
.heading-secondary {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 600;
}
.heading-tertiary {
  font-size: 2.4rem;
  line-height: 1.1;
}

/* MARGIN*/
.align-text {
  margin-left: 0.5rem;
}

.mb-sm {
  margin-bottom: 1rem;
}
.mr-sm {
  margin-right: 1rem;
}
.ml-sm {
  margin-left: 1rem;
}
.mt-sm {
  margin-top: 1rem;
}
.mb-md {
  margin-bottom: 1.6rem;
}
.mr-md {
  margin-right: 1.6rem;
}
.ml-md {
  margin-left: 1.6rem;
}
.mt-md {
  margin-top: 1.6rem;
}
.mb-lg {
  margin-bottom: 2.4rem;
}
.mr-lg {
  margin-right: 2.4rem;
}
.ml-lg {
  margin-left: 2.4rem;
}
.mt-lg {
  margin-top: 2.4rem;
}

.absolute {
  position: absolute;
  z-index: 99;
}

.relative {
  position: relative;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-num {
  font-weight: 400;
}

.section-title {
  display: flex;
}

.section-name {
  font-weight: 600;
}

.justify {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

.blob {
  width: 100%;
  position: absolute;
  z-index: -1;
  background: url("../images/blob.png");
  background-size: cover;
  opacity: 0.4;
}

.blob-big-left {
  top: 15%;
  left: -50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 80%;
  transform: rotate(180deg);
}

.blob-big-right {
  top: 38%;
  right: -20%;
  transform: translate(-50%, -50%);
  height: 40%;
  width: 40%;
}

.blob-right {
  bottom: 0;
  left: -10%;
  height: 100%;
  transform: rotate(180deg);
  opacity: 0.3;
}

.noise {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: url("../images/noise.jpg");
  background-repeat: repeat;
  /* background-size: cover; */
  opacity: 0.9;
}

.glass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}
