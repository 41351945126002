.btn {
  border: 2px solid #ffa200;
  border-radius: 5px;
  padding: 0.3rem 3rem;
  display: flex;
  background-color: transparent;
  color: #fff2db;
  cursor: pointer;
}

.btn:hover {
  color: #ffa200;
}

.cta-btn {
  font-weight: 500;
  padding: 1rem 2rem;
  font-size: 1.2rem;
}

.cta-btn-mobile {
  font-size: 1.4rem;
}

.navbar__item-small .btn {
  margin-top: 1rem;
}

.cta-btn span {
  display: flex;
  align-items: center;
}

.arrow {
  font-size: 1.4rem;
  line-height: 0;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}

.filled-button {
  color: #fff2db;
  transition: 0.4s;
}

.filled-button:hover,
.filled:focus {
  overflow: hidden;
  box-shadow: inset 14.5em 0 0 0 #ffa200;
  color: #1a1a1a;
}

.text-button {
  color: #ffa200;
}

.text-button:hover {
  color: #fff2db;
}