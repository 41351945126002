.navbar {
  position: absolute;
  z-index: 99;
  width: 100%;
  box-sizing: border-box;
  padding: 2.4rem 3rem;
  display: flex;
  justify-content: space-between;
}

.navbar__logo {
  position: relative;
  z-index: 99;
}

.navbar__list-small,
.navbar__list {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar__item {
  margin-left: 2.4rem;
}

.navbar__item-small,
.navbar__item {
  font-family: "Roboto Mono", monospace;
  list-style: none;
  font-size: 1.2rem;
}

.navbar__item-small a,
.navbar__item a {
  color: #fff2db;
  text-decoration: none;
}

.navbar__item-small a:hover,
.navbar__item a:hover {
  color: #ffa200;
}

.navbar__item-small span,
.navbar__item span {
  color: #ffa200;
}

.navbar__item span {
  margin-right: 0.8rem;
}

.navbar__nav {
  width: auto;
}

/* SMALL DEVICES */
.navbar__content-small {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar__nav-small {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #151515;
  background: url("../../images/noise.png");
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100vh;

  text-align: center;
  box-shadow: -3rem 0 5rem 1rem rgba(21, 21, 21, 0.8);
}

.navbar__list-small {
  flex-direction: column;
}

.navbar__item-small {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.navbar__item-small:last-child {
  margin-top: 1rem;
}

.navbar__item-small span {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.menu-btn {
  position: relative;
  z-index: 99;

  outline: none;
  border: none;

  cursor: pointer;
  width: 100%;
  height: 100%;
  background: transparent;
}

.nav-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(26, 26, 26, 0.26);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.8px);
  -webkit-backdrop-filter: blur(4.8px);
}
