.allprojects__content-container {
  display: flex;
  position: relative;
  /* height: 100vh; */
  /* overflow: hidden; */
}

.allprojects__content {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: 1fr;
  gap: 4rem;
  align-self: flex-end;
  height: 85%;
  width: 100%;
  /* margin: 2rem 3rem; */
  margin: 10rem 3rem 5rem 3rem;
}

.allprojects__card,
.allprojects__card a {
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
}

.allprojects__card img,
.allprojects__overlay {
  border-radius: 16px;
}

.allprojects__card a {
  position: relative;
}

.allproject-tools {
  display: flex;
  justify-content: center;
  gap: 1.6rem;
}

.allproject-tool {
  list-style: none;
}

.allprojects__overlay {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #fff2db;
  transition: all 0.5s ease;
}

.allprojects__overlay:hover {
   opacity: 1;
}

.allprojects__overlay h1 {
  text-align: center;
  text-transform: uppercase;
  /* font-size: 4rem; */
}

.allprojects__overlay ul {
  display: flex;
  gap: 1.4rem;
  font-size: 1.2rem;
}

.allprojects__overlay li {
  list-style: none;
}



@media (min-width: 992px) {
  .allprojects__content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1400px) {
  .allprojects__content {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}
