.about__content-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.about__content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 5rem;
}
@media (min-width: 1100px) {
  .content-row img {
    height: 90vh;
  }
}
@media (min-width: 1350px) {
  .content-row {
    padding: 0 15rem;
  }
}



.about-img {
  height: 55vh;
}

.about__description-container {
  width: 100%;
  position: relative;
}

.about-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4rem;
}

.about-description-tablet {
  padding: 0;
}
