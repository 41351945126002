.skills__content-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.skills__content {
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.glass-container {
  padding: 2rem;
  width: 80%;
  margin: 0 6rem;
}

.big-glass-container {
  width: 60%;
}

.mobile-glass-container {
  height: auto;
  margin: 4rem;
  padding: 2rem 4rem;
}

.skills__title-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.skills__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.8rem;
  padding: 1rem 2rem;
  justify-items: center;
}

.skills__mobile-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3.2rem;
  justify-items: center;
  margin-bottom: 1rem;
}

.skill__card {
  width: 90%;
  height: 3rem;
  background: rgba(21, 21, 21, 0.05);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  /* opacity: 0.6; */
  position: relative;
}

.skill__card-mobile {
  width: 100%;
  height: 3.2rem;
}

.skill__card-content {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.skills__description-container {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.skills__description {
  line-height: 1.4;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
}

.skill__logo-container {
  display: flex;
  justify-content: center;
  width: 20%;
}

.skill-logo {
  height: 4rem;
  width: auto;
}

.skill-mobile-logo {
  height: 5rem;
  width: auto;
}

.skill__progress-bar-container {
  display: flex;
  align-items: center;
  width: 80%;
  height: 1.2rem;
  border-radius: 1rem;
  margin-left: 20px;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
}

.progress-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  border-radius: 1rem;
  opacity: 0.8;
}

.progress-value {
  font-size: 1.2rem;
  margin-right: 1.2rem;
}
