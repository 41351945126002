body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--purple);
  --accent: var(--white);
}

/* html {
  scroll-snap-type: y mandatory;
} */

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  bottom: 100px;
}

.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 12rem;
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  color: rgba(255, 242, 219, 0.05);
  opacity: 0.5;
}

.scroller-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: absolute;
  z-index: 0;
}

.parallax span {
  display: block;
  margin-right: 3rem;
}
