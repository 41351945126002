.projects__content-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.projects__content {
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  height: 100%;
}

.projects__title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2.8rem 0; */
  height: 10%;
}

.project-space {
  height: 10%;
}

.td-content {
  width: 75%;
}

.projects__buttons {
  display: flex;
}

.projects__elements-container {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.graphic-element {
  position: absolute;
  transform: translate(-50%, -50%);

  width: 8rem;
  opacity: 0.5;
}

.tablet-graphic-element {
  width: 6rem;
}

.smaller-elements {
  width: 60%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.element1 {
  top: 34%;
  left: 82%;
}

.element2 {
  bottom: 12%;
  left: 50%;
}

.element3 {
  top: 39%;
  left: 25%;
}

.tablet-el1 {
  left: 72%;
}

.tablet-el3 {
  left: 30%;
}

.big-desc-margin {
  margin: 0 6rem;
}

.td-desc-margin {
  margin: 0 4rem;
}

.project-desc-container {
  position: absolute;
  width: 100%;
  display: none;
} 

.project-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* padding: 0 4rem; */
}

.project-title {
  /* font-size: 6rem; */
  line-height: 1;
}

.project-tools {
  margin-top: 3.6rem;
  display: flex;
  justify-content: center;
  gap: 1.4rem;
}

.project-tool {
  list-style: none;
}

.all-proj-overlay {
  position: absolute;

  height: 100%;
  background: rgba(0,0,0,0.85);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;


  /* padding: 0 5.6rem; */
}

.all-proj-overlay h1 {
  font-size: 3.6rem;
  line-height: 1.05;
  text-align: left;
  margin-bottom: 1.2rem;
}

.all-proj-overlay p {
  text-align: left;
  margin-right: 4.2rem;
  margin-bottom: 3rem;
}

@media(min-width: 1700px) {
  .all-proj-overlay {
    padding: 0 7.2rem !important;
  }
}

@media (min-width: 1565px) {
  .all-proj-overlay h1 {
    font-size: 5.6rem !important;
  }
}

@media (min-width: 1800px) {
  .all-proj-overlay h1 {
    font-size: 6rem !important;
  }
}


