@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800;900&family=Roboto+Mono:wght@400;500;600;700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #1a1a1a;
  font-size: 1.4rem;
  line-height: 1.6;
  height: auto;

  /* test */
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
  height: auto;
  scroll-behavior: smooth;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

section {
  height: 100vh;
  top: 0;
}

@media (min-width: 48em) {
  html {
    font-size: 75%; /* 12px/16px = 0.75 */
  }
}

@media (min-width: 76em) {
  html {
    font-size: 87.5%;
  }
}

.landing, .all-projects {
  position: relative;
}
