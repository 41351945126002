.hero__container {
  height: 100vh;
}

.fg-container {
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
}

.fg {
  opacity: 0.5;
  height: 60%;

  position: absolute;
  bottom: 0;
  left: 50%;

  min-height: 50%;
  min-width: 100%;

  transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}

.hero__content {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #fff2db;
  padding: 0 5rem;
  z-index: 3;
}

.hero__content-tablet {
  padding: 0 7rem;
}

.hero__content-desktop {
  padding: 0 12rem;
}

@media (min-width: 1352px) {
  .content-big-screen {
    width: 70%;
  }

  .hero__content {
    padding-left: 16rem;
  }
}

.inset {
  position: absolute;
  z-index: 80;
  height: 100vh;
  width: 100%;
}

.white {
  color: white;
}

.gray {
  color: #446;
}

.full {
  height: 100vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient {
  background: linear-gradient(rgba(26, 26, 26, 0.5) 30%, #1a1a1a);
}
