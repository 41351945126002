.contact__content-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.contact__content {
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
}

.contact-img-container {
  height: 40%;
  width: 100%;
  margin: 3.6rem 0;
}

.contact-img {
  background: url("../../images/contact.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  border-radius: 16px;
  margin: 0 3.6rem;
}

.contact__desc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.contact-desc {
  width: 70%;
}

.contact__links {
  margin: auto;
  list-style: none;
  display: flex;
  gap: 0.8rem;
}

.contact-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff2db;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 100%;
  overflow: hidden;

  transition: transform 0.2s ease-in;
}

li.contact-link:hover {
  transform: translateY(-20%) !important;
}

.contact-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.contact__copyright-container {
  margin-top: auto;
  margin-bottom: 0.8rem;
}

.contact__copyright-container p {
  font-size: 1.2rem;
}
